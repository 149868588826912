@import "../../../../../../../sass/util/breakpoints"
.content
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  padding-bottom: 8px
  .name
    flex : 3
    font-size: 14px
    color: #1a1a1a
    font-size: 14px
    font-weight: bold
    @include pos-cashier
      font-size: 12px
  .price
    display: flex
    flex: 1
    flex-direction: row
    justify-content: space-between
    span
      font-size: 14px
      font-weight: bold
      text-align: right
      color: #1a1a1a
      @include pos-cashier
        font-size: 12px
      
      
