@import sass/colors
@import sass/animations

html, body, #root
  overscroll-behavior: none
  min-height: 100%

body
  margin: 0
  padding: 0
  overflow-x: hidden
  text-align: initial
  background-color: #1a1a1a

  span
    font-size: 1.4rem

*
  font-family: 'A Jannat LT', 'Comfortaa', sans-serif
  box-sizing: border-box
  margin: 0
  padding: 0

*,
*::after,
*::before
  box-sizing: inherit
  cursor: inherit

.material-icons
  font-family: 'Material Icons', sans-serif !important
  font-weight: normal
  font-style: normal
  font-size: 24px
  line-height: 1
  letter-spacing: normal
  text-transform: none
  display: inline-block
  white-space: nowrap
  word-wrap: normal
  direction: ltr
  -webkit-font-feature-settings: 'liga'
  -webkit-font-smoothing: antialiased

html
  box-sizing: border-box
  font-size: 62.5%

  @media only screen and (max-width: $bg-largest-1)
    font-size: 70%

  @media only screen and (max-width: $bg-largest-2)
    font-size: 62.5%

  @media only screen and (max-width: $bg-largest-3)
    font-size: 60%

  @media only screen and (max-width: $bg-large)
    font-size: 60%

.rc-notification-notice, .rc-notification-fade-enter-active
  opacity: 1 !important
  transition: all 300ms !important

  &.rc-notification-fade-leave-active,
  &.rc-notification-fade-enter
    opacity: 0 !important

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
