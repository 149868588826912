@import ../../../../sass/animations
@import ../../../../sass/util/breakpoints

.orderWrapper
  width: 100%
  .pickupDelivery
    .shipoxWrapper
      display: flex
      flex-direction: column
      align-items: center
      flex: 1
      height: 100%
      border-radius: 5px
      .shopxIcon
        display: flex
        flex-direction: column
        width: 68px
        height: 100%
        border-radius: 5px
        align-items: center
        img
          margin: auto
    .imgContent
      background-color: rgba(#f09440, 0.2)
  .pickupDelivery,
  .pickupOrders, .qrOrders
    flex: 1
    height: 100%
    border-radius: 5px
    .imgContent
      display: flex
      width: 68px
      height: 100%
      border-radius: 5px 0 0 5px
      justify-content: space-between
      img
        margin: auto
  .pickupOrders, .qrOrders
    background-color: #ffffff
    .imgContent
      background-color: rgba(65, 18, 108,0.2)
  .qrOrders
    background-color: #fff
    .imgContent
      background-color: #e7f8ee
  .orderExitWrapper
    transform-origin: 100% 0

  .contentWrapper
    flex: 20
    height: 88px
    margin-bottom: 16px
    display: flex
    flex-direction: row
    border-radius: 5px
    // justify-content: space-evenly
    box-shadow: 0 6px 12px 0 rgba(31, 4, 48, .1)
    width: 100%
    @include pos-cashier()
      height: 120px
    &.disappearing
      animation: collapse-and-disappear 0.4s

    .statusIcon
      width: 36px
      flex: 1
      background-color: #f7cc49
      align-items: center
      justify-content: center
      display: none

      img
        width: 25px
    .main
      display: flex
      flex-direction: column
      flex: 20
      border-radius: 0 5px 5px 0
      &.rtl
        border-radius: 5px 0 0 5px    
      .timingWrapper
        display: none
        @include pos-cashier()
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center
          margin-bottom: 12px
        .timingText, .timingSubtext
          font-size: 18px
          font-weight: 400
        .timingSubtext
          font-weight: 800  
          margin: 0 4px
      .wrapper
        padding: 16px 24px
        flex: 20
        background-color: #ffffff
        display: flex
        flex-direction: row
        justify-content: space-evenly
        border-radius: 5px
        @include pos-cashier()
          position: relative
          padding: 16px 8px 8px 0

        .pickUpTimeWrapper
          display: flex
          flex-direction: column !important
          flex: 1
          width: 100%
          @include pos-cashier()
            display: none !important
          // position: absolute
          // top: 45px
          // right: 0
        .pickupWrapper
          //width: 68px
          //height: 100%
          //opacity: 0.2
          //border-radius: 5px
          background-color: rgba(65,18,108,0.2) 
        .providerWrapper
          display: flex
          flex-direction: row
          align-items: center
          flex: 1
          @include pos-cashier()
          flex: 1
          .providerIcon
            width: 32px
            height: 32px
          .providerTextsWrapper
            display: flex
            flex-direction: column
            margin: 0 10px

            .providerText
              font-size: 17px
              font-weight: 700
            .from
              font-size: 13px
              font-weight: 400
              margin-bottom: 4px
        .orderCodeWrapper, .pickUpTimeWrapper, .userNameWrapper
          display: flex
          justify-content: center
          flex-direction: column
          align-items: flex-start
          align-self: center
          flex: 1

          @include pos-cashier()
          justify-content: flex-start
          align-self: none
          .userName
            font-size: 14px
            color: #a6a6a6
          .info
            font-size: 18px
            font-weight: bold
            color: #1a1a1a
            @include pos-cashier()
            font-size: 16px
            //  margin-top: 8px
          .discountWrapper
            display: flex
            flex-direction: row
            @include pos-cashier()
            width: 100%
            height: 100%
            justify-content: flex-end
            align-items: flex-end
            margin-bottom: 8px
            .discountTitle
              font-size: 12px
              font-weight: bold
              color: #41126c
          .discountWrapperEnglishMode
            display: flex
            flex-direction: row
            @include pos-cashier()
              width: 100%
              height: 100%
              justify-content: flex-end
              align-items: flex-end

        .userNameWrapper
          flex: 1.5

        .timer
          font-size: 14px
          color: #a6a6a6
          display: flex
          align-items: center
          padding: 0 16px
      &, .wrapper
        background-color: #fff
    &.pendingWrapper
      .main
        .timingWrapper
          .timingText, .timingSubtext
            color: #fff
        &, .wrapper, .pickupOrders, .pickupDelivery, .statusIcon, .qrOrders
          background-color: #f09440

        .wrapper
          .providerWrapper
            .providerTextsWrapper
              .providerText
                color: #fff
              .from
                color: #fff 
          .orderCodeWrapper, .pickUpTimeWrapper, .userNameWrapper
            .userName
              color: rgba(#ffffff, .7)
              
            .info, .extraInfoFont
              color: #ffffff

          .timer
            color: rgba(#ffffff, .7)

    &.alarmWrapper
      .main
        .timingWrapper
          .timingText, .timingSubtext
            color: #fff
        &, .wrapper, .statusIcon, .pickupOrders, .pickupDelivery, .qrOrders

          display: flex
          background-color: #db3030 !important

        .wrapper
          .providerWrapper
            .providerTextsWrapper
              .providerText
                color: #fff
              .from
                color: #fff 
          .orderCodeWrapper, .pickUpTimeWrapper, .userNameWrapper
            .userName
              color: rgba(#ffffff, .7)

            .info, .extraInfoFont
              color: #ffffff

          .timer
            color: rgba(#ffffff, .7)
            // position: relative
            padding: 0 16px
            // &.rtl
            //   color: rgba(#ffffff, .7)

            // @include pos-cashier()
            // top: -20px
          .timertimerWithArabic
            color: rgba(#ffffff, .7)
            position: relative
            &.rtl
            @include pos-cashier()
            left: 20px
            top: -20px
            .mosque
              position: absolute
              display: flex
              justify-content: flex-start
              width: 88px
              height: 88px
              top: -16px
              right: -27px
            &.rtl
              .mosque
                right: -15px
            // left: 27px

    &.customerArrivedWrapper
      .statusIcon
        display: flex
      

.contentWrapper
  &.rtl
    .pickupDelivery,
    .pickupOrders, .qrOrders
      .imgContent
        border-radius: 0 5px 5px 0
      .alarm
        box-shadow: -2px 0 5px 0 rgba(144, 0, 0, 0.15)
      .pending
        box-shadow: -2px 0 5px 0 rgba(240, 148, 64, 0.15)

.pending
  background-color: rgb(240, 148, 64) !important
  box-shadow: 2px 0 5px 0 rgba(240, 148, 64, 0.15)

.normal
  background-color: rgba(65, 18, 108,0.2)
.alarm
  box-shadow: 2px 0 5px 0 rgba(144, 0, 0, 0.15)
  position: relative
  background-color: rgb(219, 48, 48) !important

.promoIcon
  @include pos-cashier()
    width: 16px
    height: 16px
    margin-bottom: 4px
