@import '../../../sass/util/breakpoints'
.wrapper
  display: flex
  position: fixed
  z-index: 1400
  height: 100%
  width: 100%
  background-color: #41126c
  color: white
  flex-direction: column
  align-items: center
  justify-content: center
  .ordersCount
    border-radius: 50%
    width: 250px
    height: 250px
    background-color: #5b1998
    padding: 30px
    display: flex
    .innerCircle
      font-size: 132px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 50%
      flex: 1
      background-color: #4e1682

  .title
    h2
      font-size: 32px
      font-weight: bold

  .subTitle
    h2
      margin-top: 12px
      font-size: 24px
      color: rgba(#fff, 0.5)

@include pos-cashier()
  .wrapper
    align-items: center
    justify-content: flex-start
    .title
      padding: 8px
      text-align: center
      h2
        font-size: 18px
        margin-top: 16px
    .subTitle
      text-align: center
      h2
        font-size: 18px
        color: #FFF
    .ordersCount
      margin-top: 24px
    .discountContainer
      background-color: #DB3030
      width: 170px
      height: 170px
      border-radius: 95px
      display: flex
      justify-content: center
      align-items: center
    .lockIcon
      width: 120px
      height: 120px
